


import { Row, Col, Badge } from 'react-bootstrap';
import { views } from './Views';
import './Contact.css';

const MailsList = ({ type, mails, setActiveMail }) => {
  const height ='80vh';
  const sorted = mails.sort((a,b) => a.id < b.id && 1 || -1);
  const breakText='text-break';

  const showTitle = obj => {
    let tempTitle;
    if (obj.length === 0) {
      tempTitle = `NO ITEMS IN '${type.toUpperCase()}'`;
    } else {
      tempTitle = '';
    }
    return tempTitle;
  }

  return (
    <div className='mail-list-block' style={{ maxHeight: height, overflowY: 'auto',marginTop:'30px' }}>
      <h3 style={{ padding: '0 20px' }}>
        <i style={{color: 'lightgray' }}>{showTitle(mails)}</i>
      </h3>
      {mails.length > 0 &&
        <div style={{ color: 'rgb(179, 179, 179)', padding: '0 5px 20px 5px', fontSize: '20px' }}>{type.toUpperCase()}</div>
      }

      {sorted.map((mail, index) => (
        <Row
          className={'inboxItem pt-3 pb-3 mb-3 ml-1 mr-1'}
          style={{borderRadius:'16px'}}
          onClick={() => setActiveMail(mail)}
          key={index}
          data-testid={`mailItem${index}`}
        >
          <Col style={{borderRight: '1px dotted gray',display:'flex'}} xs={4}>
            <div style={{padding:'10px 0'}} className={breakText}>
              {!mail.viewed && type === views.INBOX && <Badge style={{color: 'green'}}>&#x25cf;</Badge>}{mail.sender}
            </div>
          </Col>
          <Col style={{borderRight: '1px dotted gray',display:'flex'}} xs={4}>
            <div style={{margin:'auto'}} className={breakText}>
              {mail.subject}
            </div>
          </Col>
          <Col style={{display:'flex'}} xs={4}>
            <div style={{marginLeft:'auto',marginTop:'auto',marginBottom:'auto'}} className={breakText}>
              {mail.dateTime}
            </div>
          </Col>
        </Row>
      ))}
    </div>
  );
};
export default MailsList;
