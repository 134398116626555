
import { useState } from 'react';
//import bootstrap components
import { Button, Row, Col, CloseButton } from 'react-bootstrap';
//import views
import { views } from './Views';
//import pretty date
import { prettyDate } from '../../_shared/utils';
//import styles
import './Contact.css';

const NewMail = ({ sendMail, back, recepient, sender, subject, toBody }) => {
    const [body, setBody] = useState(toBody);
    const [newRecipient, setNewRecipient] = useState(recepient);
    const [newSubject, setNewSubject] = useState(subject);
    const [subjectError, setSubjectError] = useState(false);
    const [bodyError, setBodyError] = useState(false);

    const height = '32vh';
    //set recepient
    const onRecepient = e => {
        setNewRecipient(e.target.value);
    };
    //set subject

    const onSubject = e => {
        setSubjectError(false);
        setNewSubject(e.target.value);
    };

    //set body

    const onBody = e => {
        setBodyError(false);
        setBody(e.target.value);
    };

    const onSubmit = () => {
        if(!newSubject) {
            setSubjectError(true);
            return;
        }
        if(!body) {
            setBodyError(true);
            return;
        }
        sendMail({
            type: views.SENT,
            recepient: newRecipient,
            subject: newSubject,
             dateTime: prettyDate() ,body,sender});
    };

    return (<div>
        <Col xs={12} className='mailContainer'>
            <Row className='new-message-title d-flex justify-content-between'>
                <span style={{color:'black'}}>New Message</span>
                <CloseButton
                    style={{marginRight:'7px'}}
                    onClick={() => back(views.SENT)}
                    data-testid='new-mail-close-btn'
                />
            </Row>

            <Row className='pl-1' style={{ borderTop: '1px solid white' }}>
                <Col className='mail-to' sm={12} style={{width: '100%', marginRight: '0px'}}>
                    <span style={{marginRight: 47,color:'black'}}>To</span>
                    <input
                        className='input-field inputbox'
                        type='text'
                        onChange={onRecepient}
                        value={newRecipient}
                        readOnly
                        data-testid='new-mail-input-to'
                    />
                </Col>

                <Col className='pt-2 mail-sub' sm={12} style={{width: '100%', marginRight: '0px'}}>
                    <span style={{marginRight: 10,color:'black'}}>Subject</span>
                    <input
                        className='input-field inputbox'
                        type='text'
                        onChange={onSubject}
                        value={newSubject}
                        readOnly
                        data-testid='new-mail-input-subject'
                    />

                    {subjectError &&
                        <span style={{color: 'red'}}>*Subject cannot be empty</span>
                    }
                </Col>
            </Row>

            <Row className='mt-3 ml-1' style={{ height, overflowY: 'scroll', width: '99.5%' }}>
                <textarea
                    onChange={onBody}
                    style={{width: '100%', border: '1px solid lightgray',padding:'20px'}}
                    value={body}
                    data-testid='new-mail-input-body'
                />
            </Row>

            {bodyError && <span style={{color: 'red', paddingLeft: '5px'}}>*Message body cannot be empty</span>}

            <Row className='p-1 m-1 float-right'>
                <Button
                    variant='secondary'
                    style={{borderRadius:'5px',width:'100px',marginTop:'20px' }}
                    size='md'
                    onClick={onSubmit}
                    data-testid='new-mail-send-btn'
                >
                    Send
                </Button>
            </Row>
        </Col>
    </div>);
};
export default NewMail;
