
import React, {
  Suspense
} from 'react';
// React dom added to render the components.
import ReactDOM from 'react-dom';
// Context provider.
import { GameStateProvider } from './context/provider';
// Login Component.
import Login from './components/login/Login';
// Bootstrap style added.
import 'bootstrap/dist/css/bootstrap.min.css';
//import styles
import './index.css';


// Adding skip links at the top of your application - Accessibility Fix
const SkipLinks = () => (
  <div className="skip-links">
    <a href="#content">Skip to main content</a>
  </div>
);


// Application starts here.
ReactDOM.render(
  // Strict mode added.
  <React.StrictMode>
    <GameStateProvider>
    <SkipLinks /> {/* This line renders the SkipLinks component */}
      {/* Suspense added */}
      <Suspense
        fallback={
          <div>Loading...</div>
        }
      >
        {/* Login */}
        <Login />
      </Suspense>
    </GameStateProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
