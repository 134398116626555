

//import styles
import './Contact.css';

// Header component
const Header = ({ close, toggleNav }) => {
    return (
        // flex block
        <div className='d-flex justify-content-between con-hdr'>
            {/* for mobile Hamburger menu */}
            <button
                onClick={toggleNav}
                type='button'
                className={'contact-menu-icon'}
                // custom style here
                style={{
                    width: '4vh',
                    border: '0px transparent',
                    backgroundColor: 'transparent'}}
                data-testid='toggle-btn'
            >
                {/* to show hamburger icon */}
                <img
                    src={'https://cdn.huntakiller.com/rip-at-the-rodeo/menu-hamburger-white.svg'}
                    alt={'Menu'}
                />
            </button>

            {/* for Desktop */}
            <div
                // custom styles
                style={{
                    color: 'rgb(50, 51, 51)',
                    fontWeight: '600'}}
            >
                {/* contact title */}
                <h5 style={{
                    margin: '0',
                    marginTop: '5px'}}
                >
                    CONTACT
                </h5>
            </div>

            {/* close button */}
            <button
                type='button'
                // custom style here
                style={{
                    width: '5vh',
                    marginBottom:'5px',
                    marginRight:'5px',
                    border: '0px transparent',
                    backgroundColor: 'transparent'}}
                onClick={close}
            >
                {/* to show close icon */}
                <img
                    src='https://cdn.huntakiller.com/rip-at-the-rodeo/mediaplayer-close-button.svg'
                    alt='Close'
                />
            </button>
        </div>
    );
};
//export header component
export default Header;
