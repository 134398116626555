

import React,{ useEffect, useState } from 'react';
//import bootstrap components
import { Button, Row, Col } from 'react-bootstrap';
//import image

import Image from './../viewer/ImageViewer';
//import views
import { views } from './Views';
//import styles
import './Contact.css';

const ViewMail = ({ vh, mail, back, trash, height, setViewed }) => {
    const [attach, setAttach] = useState(null);

    const hideAttach = () => {
        setAttach(null);
    };

    useEffect(() => {
        setViewed(mail.id);
    }, []);

    return <>
        {attach?.type === 'image' &&
            <Image
                icon={{ label: attach.label, url: [attach.url], vh }}
                close={hideAttach}
            />
        }

        <Row className='viewmailContainer' style={{marginTop:'20px', marginLeft:'10px'}}>
            <Col xs={12} className=' pl-0 btn-back' style={{backgroundColor:'white', height:'65px'}}>
                <Button
                    className=''
                    style={{
                        backgroundColor:'#b3b3b3',
                        width:'200px',
                        borderRadius:'10px',
                        borderColor: 'transparent'}}
                    variant='secondary'
                    size='lg'
                    onClick={() => back(mail.type)}
                    data-testid='back-btn'
                >
                    Back
                </Button>
            </Col>
            <Col xs={12} style={{color:'black'}}>
                <Row className='p-2 m-2 d-flex justify-content-between'>
                    <span>Subject: RE: {' '}{mail.subject}</span>
                    <span>Date, Time: {' '}{mail.dateTime}</span>
                </Row>
                <Row className='p-2 m-2' style={{ borderTop: '1px solid white' }}>
                    <span>Sender: {' '}{mail.sender}</span>
                </Row>

                <Row
                    className='p-3 m-2'
                    style={{
                        height,
                        backgroundColor: 'white',
                        border: '1px solid lightgray',
                        overflowY: 'scroll',
                        width: '98%'}}
                >
                    <div style={{ width: '100%' }}>
                        <span style={{whiteSpace: 'pre-line',color:'black'}}>{mail.body}</span>
                        <div style={{height: '20px'}}></div>
                        <ul >
                            {mail?.attachments?.map(attachment => (
                                // <li key={attachment.label}>
                                //     <a href='#' className='link-primary' onClick={() => setAttach(attachment)}>{attachment.label}</a>
                                //     <p style={{whiteSpace: 'pre-line',color:'black'}}>{attachment.description}</p>
                                // </li>

                                <div
                                    onClick={() => setAttach(attachment)}
                                    key={attachment.label}
                                    data-testid='mail-attach-id'
                                >
                                    <img
                                        className='attachment'
                                        src='https://cdn.huntakiller.com/rip-at-the-rodeo/rosario-letter-icon.svg'
                                        alt='Rosario Garcia' />
                                </div>
                            ))}
                        </ul>
                    </div>
                </Row>
                {mail.type !== views.TRASH && <Row className='p-2 m-2 float-right'>
                    <Button
                        variant='secondary'
                        size='lg'
                        onClick={() => trash(mail)}
                        style={{
                            backgroundColor:'#787878',
                            width:'100px',
                            borderRadius:'10px'}}
                        data-testid='trash-btn'
                    >
                        Trash
                    </Button>
                </Row>}
            </Col>
        </Row>
    </>;
};
export default ViewMail;
