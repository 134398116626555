
/* login component*/
import React, { useEffect, useContext, useState } from 'react';
// full screen library
import Div100vh from 'react-div-100vh';
//import desktop
import Desktop from '../desktop/Desktop';
//import context
import { GameStateContext } from '../../context/context';
//import styles
import './Login.css';

// Calling login component
const Login = () => {
    const REACT_APP_HAK_RIP_AT_THE_RODEO_PWD = process.env.REACT_APP_HAK_RIP_AT_THE_RODEO_PWD;
    // context
    const context = useContext(GameStateContext);
    // user login verifier
    const isUserLoggedIn = localStorage.getItem('userLoggedIn') || false;
    const [userLoggedIn, setLoggedIn] = useState(isUserLoggedIn);

    const [userPwd, setUserPwd] = useState('');
    const [error, setError] = useState('');

    // useEffect
    useEffect(() => {
        setError('');
    }, [userPwd]);

    // Logout
    const handleLogout = () => {
        setLoggedIn(false);
        // to clear local storage
        localStorage.clear();
        // to reload
        window.open('/', '_self');
    };

    // to verify password
    const handleEnter = () => {
        // to check password is true or not
        if (userPwd.toUpperCase() === REACT_APP_HAK_RIP_AT_THE_RODEO_PWD) {
            setUserPwd('');
            // state update
            setLoggedIn(true);
            // to set login true when pwd verifies to maintain state
            localStorage.setItem('userLoggedIn', true);
            // update context
            context?.updateState({ episode: 1, cta: 1, chat: [] });
        } else {
            // to set error msg when checks fails
            setError('Invalid Password');
        }
    };

    return (
        // Parent block
        <Div100vh dontresize='true' className='vh-100 vw-100 hak-desktop' id='content'>
            {/* to show desktop when user login success */}
            {userLoggedIn &&
                <Desktop handleLogout={handleLogout} />
            }

            {/* to show password screen */}
            {!userLoggedIn &&
                <>
                    {/* Brand logo */}
                    <img
                        className={'hak-desktop-background'}
                        src={'https://cdn.huntakiller.com/rip-at-the-rodeo/hawk-springs-rodeo-logo.svg'}
                        alt={'hak_rip_at_the_rodeo_desktopbackground'}
                    />
                    {/* password field */}
                    <input
                        className='hak-desktop-pwd-input'
                        type='password'
                        value={userPwd}
                        onChange={e => setUserPwd(e.target.value)}
                        placeholder={'Password'}
                        onKeyUp={e => e.key === 'Enter' && handleEnter()}
                        data-testid='userPwd'
                    />
                    {/* Error message */}
                    <span className='text-error'>{error}</span>
                    {/* Enter button */}
                    <button
                        className={'hak-login-button'}
                        onClick={handleEnter}
                        data-testid="enterBtn"
                    >
                        ENTER
                    </button>
                </>
            }
        </Div100vh>
    );
};
export default Login;

