


import { Row } from 'react-bootstrap';
//import views
import { views } from './Views';
//import styles
import './Contact.css';

const LeftNav = ({ activeView , setActive }) => {
    return (
        <div style={{overflow:'scroll',marginTop:'25px'}}>
            <Row className = {'p-2 m-2 btn-new-msg'}>
                <img
                    style={{cursor: 'pointer', width: '100%'}}
                    onClick={() => setActive(views.NEW)}
                    src={'https://cdn.huntakiller.com/rip-at-the-rodeo/email-newmessage-button.svg'}
                    alt='new'
                    data-testid='mail-new'
                />
            </Row>
            <Row className ={'contact-left-navitem p-2 m-2 no-border-top'}>
                <img
                    style={{cursor: 'pointer' }}
                    onClick={() => setActive(views.INBOX)}
                    src={'https://cdn.huntakiller.com/rip-at-the-rodeo/email-menu-inbox-button.svg'}
                    alt='inbox'
                    data-testid='mail-inbox'
                />
            </Row>
            <Row className = {'contact-left-navitem p-2 pt-3 m-2'}>
                <img
                    style={{cursor: 'pointer' }}
                    onClick={() => setActive(views.SENT)}
                    src={'https://cdn.huntakiller.com/rip-at-the-rodeo/email-menu-sent-button.svg'}
                    alt='sent'
                    data-testid='mail-sent'
                />
            </Row>
            <Row className = {'contact-left-navitem p-2 m-2'}>
                <img
                    style={{cursor: 'pointer' }}
                    onClick={() => setActive(views.TRASH)}
                    src={'https://cdn.huntakiller.com/rip-at-the-rodeo/email-menu-trash-button.svg'}
                    alt='trash'
                    data-testid='mail-trash'
                />
            </Row>
        </div>
    );
};
export default LeftNav;
